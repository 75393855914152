<template>
  <unauthenticated-form>
    <form @submit.prevent="onSubmit">
      <BfInput
        v-model="email"
        label="Email Address"
        name="username"
        type="email"
        autofocus
      />
      <BfInput
        type="submit"
        value="Reset Password"
      />
      <el-alert
        v-if="error"
        type="error"
        :closable="false"
      >
        {{ error }}
      </el-alert>
      <el-button
        class="back-button"
        type="outline"
        @click="backToLogin"
      >
        Return to Sign In
      </el-button>
    </form>
  </unauthenticated-form>
</template>

<script>
import Auth from '@aws-amplify/auth'

import BfInput from '@/components/BfInput/BfInput'
import UnauthenticatedForm from '@/components/UnauthenticatedForm/UnauthenticatedForm'
import { mapActions } from 'vuex'

export default {
  name: 'ForgotPassword',
  components: { BfInput, UnauthenticatedForm },
  data: () => ({
    email: '',
    error: null
  }),
  methods: {
    ...mapActions(['flash']),
    async onSubmit(evt) {
      if (this.email.length > 0) {
        this.error = null
        // No matter the result of the forgot password action, show a success
        await Auth.forgotPassword(this.email)
          .then(() => {
            this.flash(`An email has been sent to ${this.email}`)
            this.$router.push({ name: 'login' })
          })
          .catch(err => {
            if (['TooManyRequestsException', 'LimitExceededException'].includes(err.code)) {
              this.error = 'You have reset your password too many times. Please try again later.'
            } else {
              this.flash(`An email has been sent to ${this.email}`)
              this.$router.push({ name: 'login' })
            }
          })
      }
    },
    backToLogin() {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
